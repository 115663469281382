const services = [
    {
        id: 0,
        title: "Urbanisme et Développement Durable",
        icon: "./img/icones/0.svg",
    },
    {
        id: 200,
        title: "Architecture et Construction",
        icon: "./img/icones/200.svg",
    },
    {
        id: 1,
        title: "Planification et Gestion des Projets",
        icon: "./img/icones/1.svg",
    },
    {
        id: 2,
        title: "Aménagement Spatial",
        icon: "./img/icones/2.svg",
    },
    {
        id: 3,
        title: "Gestion des Risques et Résilience Urbaine",
        icon: "./img/icones/3.svg",
    },
    {
        id: 4,
        title: "Conception, Suivi et Évaluation des Projets",
        icon: "./img/icones/4.svg",
    },
    {
        id: 5,
        title: "Gestion et Protection de l'Environnement",
        icon: "./img/icones/5.jpg",
    },
    {
        id: 6,
        title: "La Foresterie, l'Agroforesterie et le Reboisement",
        icon: "./img/icones/6.svg",
    },
    {
        id: 7,
        title: "L'Agriculture et l'Élevage",
        icon: "./img/icones/7.svg",
    },
    {
        id: 8,
        title: "L'Hydrogéologie",
        icon: "./img/icones/8.jpg",
    },
    {
        id: 9,
        title: "Mines et Géologie",
        icon: "./img/icones/9.jpg",
    },
    {
        id: 10,
        title: "SIG et la Géomatique (Télédétection et Topographie)",
        icon: "./img/icones/10.jpg",
    },
    {
        id: 11,
        title: "Création de Bases de Données Géographiques",
        icon: "./img/icones/11.svg",
    },
];

export default services;
