const projets = [
    {
        id: 0,
        title: "Pont Kyahali-Isango",
        img: "./img/projets/1.JPG",
        desc: "Construction d'un pont reliant la cellule Kyahali et Isango dans le quartier Mukuna | Butembo",
    },
    {
        id: 1,
        title: "Restructuration Kintambo Magasin | KINSHASA",
        img: "./img/projets/0.JPG",
        desc: "Restructuration d'une aire de centralité de KINTAMBO MAGASIN | KINSHASA",
    },
    {
        id: 2,
        title: "Cité Résiliente 'Volcano City'",
        img: "./img/projets/2.JPG",
        desc: "Proposition d'aménagement d'une cité résiliente (VOLCANO CITY) pour l'accueil des déplacés du volcan Nyiragongo | GOMA",
    },
    {
        id: 9,
        title: "Vue aérienne de la cité résiliente VOLCANO CITY | GOMA",
        img: "./img/projets/11.jpg",
        desc: "Vue aérienne de la cité résiliente VOLCANO CITY à Goma",
    },
    {
        id: 3,
        title: "École Primaire Musulmane (E.P Livate) | BUTEMBO",
        img: "./img/projets/3.JPG",
        desc: "Construction d'une école primaire musulmane dans le quartier WAYENE en ville de Butembo"
    },
    {
        id: 4,
        title: "Construction d'appartements résidentiels dans le quartier Himbi | GOMA",
        img: "./img/projets/5.JPG"
    },
    {
        id: 5,
        title: "Aménagement de la place publique de KINTAMBO MAGASIN | KINSHASA",
        img: "./img/projets/7.JPG",
        desc: "Aménagement de la place publique de KINTAMBO MAGASIN à Kinshasa",
    },
    {
        id: 6,
        title: "Carte d'Analyse de la Vulnérabilité de la Ville de Goma face aux Aléas Volcaniques et Sismiques",
        img: "./img/projets/9.jpg",
        desc: "Carte d'Analyse de la Vulnérabilité de la Ville de Goma face aux Aléas Volcaniques et Sismiques",
    },
    {
        id: 8,
        title: "Étude et Levé Topographique du Jardin des Morts entre Terre et Ciel (Nécropole Kinshasa)",
        img: "./img/projets/10.jpg",
        desc: "Mise en place d'un plan de lotissement (trame assainie et extension du jardin de mort) et faciliter les études de conceptions architecturale",
    },
    {
        id: 7,
        title: "Colloque de la Journée Internationale de l'Urbanisme",
        img: "./img/projets/8.JPG",
        desc: "Colloque de la Journée Internationale de l'Urbanisme et de l'Habitat (MIND THE GAP) du 4 octobre 2022",
    },
];

export default projets;
