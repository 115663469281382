import React from 'react'
import articles from '../data/blog';
import Header from '../components/Header';
import Footer from '../components/Footer';
import rapport from '../data/rapport';



function Blog() {
   
    return (
        <div className='blog'>
          <Header title="NOTRE BLOG"   videoSrc='./video/2.mp4'/>
          <div className="topBox">
            <h1>Articles recents</h1>
          </div>
            <div className="articlePush">
                {articles.map((art) => 
                    <Article key={art.id} img={art.img} title={art.title} desc={art.miniText} time={art.time} author={art.author} date={art.date} link={art.link} />
                )}
            </div>
            <div className="topBox">
                <h1>Quelques Rapports</h1>
            </div>
            <div className="rapportPush">
                {rapport.map((art) => 
                    <Rapports key={art.id} src={art.src} name={art.name}/>
                )}
            </div>
            <Footer/>
        </div>
    )
}

function Article(props){
    return (
       <>
        <div key={props.id} className='article' data-aos="zoom-in">
            <div className="top">
                <img src={props.img} width={200} alt="img" />
            </div>
            <div className="bottom">
                <p>{props.author}.{props.date} </p>
                <h3>{props.title}</h3>
                <p>{props.desc}</p>
                <a href={props.link} target='_blank' rel='noopener noreferrer'>En savoir plus</a>
                <p>{props.time}</p>
            </div>
        </div>
       </>
    )
}


function Rapports(props) {
    return (
        <div className='rapport'>
            <h2>{props.name}</h2>
            <embed src={props.src} width="80%" height="800px" type="application/pdf" />
        </div>
    );
}
export default Blog;
