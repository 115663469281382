import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Recent from '../components/Recent';
import Footer from '../components/Footer';
import Equipe from '../components/Equipe';
import Partenaires from '../components/Partenaires';

function Home() {
    // Script pour faire défiler en haut
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    // Fin du script pour faire défiler en haut

    return (
        <div className='home'>
            <Header title="Kivu Urbanist Company" videoSrc='./video/2.mp4' description="Engagé pour la planification rationnelle, responsable, participative et le développement résilient durable." />
            <div className="main">
                {/* À propos */}
                <div className="about">
                    <div className="left" data-aos="fade-up">
                        <h1>À propos de nous</h1>
                        <p>
                            Kivu Urbanist Company est un cabinet d’étude initié par des jeunes valeureux, experts et membres
                            de la Corporation nationale des Urbanistes Congolais, basés à l’Est de la République Démocratique du Congo.
                            Il est spécialisé dans le domaine du génie territorial et urbain :
                            
                            <ul>
                                <li> l’aménagement et planification spatiale durable</li>
                                <li> la mobilité intelligente et partagée</li>
                                <li>l’assainissement, la gestion résiliente des risques urbains, la construction</li>
                                <li>la protection ainsi que la conservation de l’environnement par le « Smart Management et l’intelligence artificielle (IA) » 
                                    orientée vers le Système d’Information Géographique (SIG) et la géomatique.</li>
                            </ul>
                        </p>
                        
                        <div className="quoteDiv">
                            <p className='quote'>
                                « Penser le devenir d’une ville c’est lui offrir une durabilité perpétuelle par une planification responsable,
                                inclusive et participative des bénéficiaires directs et/ou lointains basée sur les enjeux locaux et les objectifs
                                du développement durable ainsi que la réconciliation environnementale ».
                            </p>
                            <h3 className="signature">Urbanizado Providence Siriwayo</h3>
                        </div>
                        <Link onClick={scrollToTop} to='/aboutUs'><button>En savoir plus</button></Link>
                    </div>
                    <div className="right" data-aos="fade-left">
                        <img src="./img/equipe.JPG" alt="équipe" />
                    </div>
                </div>
                {/* Fin de la section À propos */}
                {/* Section d'accueil */}
                <div className="acceuil" data-aos="fade-up">
                    <p className='pTop'><span>Motivations</span></p>
                    <h1>Placer <span>l'humain</span> au centre de nos expériences</h1>
                    <p className='missionDesc'>
                    <h2>Notre mission : </h2>
                        « Aménager, construire et assainir les espaces urbains et ruraux en vue d’assurer le mieux-être
                        de l’homme, améliorer les rapports sociaux en préservant propice l’environnement et accompagner les autorités
                        à instruire la population sur le droit d’occupation du sol dans le but d’éviter les constructions anarchiques et
                        promouvoir la profession d’Urbaniste en R.D.C ».
                    </p>
                    <Link onClick={scrollToTop} to='/services'><button>Nos services</button></Link>
                </div>
                {/* Fin de la section d'accueil */}
                {/* Section des travaux récents */}
                <Recent />
                {/* Fin de la section des travaux récents */}
                {/* Section des professionnels */}
                <div className="devs" data-aos="fade-up">
                    <p className='pTop'><span>Vision</span></p>
                    <h1>La planification intelligente et <span>l'aménagement résilient</span> restent la <span>solution</span></h1>
                    <p>
                        La société a pour objet principal, en République Démocratique du Congo et en dehors du pays, tant pour elle-même que pour
                        le compte des tiers, personnes physiques ou morales, soit conjointement ou en participation ou sous toute autre forme
                        légale, toutes activités se rapportant directement ou indirectement aux :
                    </p>
                    <ul>
                        <li>Planification des projets de développement local, urbain et régional ;</li>
                        <li>Aménagement des aires protégées, zones d’activités et économiques spéciales ;</li>
                        <li>Gestion, protection et étude d'impacts environnementaux ;</li>
                        <li>L'inventaire multi-ressource et aménagement des forêts ;</li>
                        <li>...</li>
                    </ul>
                    <Link onClick={scrollToTop} to='/services'><button>En savoir plus</button></Link>
                </div>
                {/* Fin de la section des professionnels */}
                {/* Section de l'équipe */}
                <div className="equipe">
                    <Equipe />
                </div>
                {/* Fin de la section de l'équipe */}
                {/* Description de l'approche */}
                <div className="demarche">
                    <div className="layer"></div>
                    <div className="content" data-aos="zoom-in">
                        <div className="img">
                            <img src="./img/logo.png" alt="logo" />
                        </div>
                        <h1>Construisons un futur durable avec innovation et savoir-faire</h1>
                        <p>
                            Chez Kivu Urbanist's Company, notre priorité est d'apporter des solutions participatives, excellentes, durables et
                            innovantes pour un avenir meilleur de nos partenaires et de la population cible. Nous combinons nos expertises et nos
                            connaissances approfondies en urbanisme et gestion de projets pour un environnement résilient et durable.
                        </p>
                        <Link onClick={scrollToTop} to='/contact' className='btn'>Contact</Link>
                    </div>
                </div>
                {/* Fin de la description de l'approche */}
                {/* Section de la confiance */}
                <div className="confiance">
                    <Partenaires />
                </div>
                {/* Fin de la section de la confiance */}
            </div>
            <Footer />
        </div>
    );
}

export default Home;
