import React from 'react'
import Footer from '../components/Footer';
import Header from '../components/Header';

function Contact() {
    return (
        <div className='contact'>
            <Header title='NOUS CONTACTER'   videoSrc='./video/2.mp4' />
           <main>
                <div className="top" data-aos="fade-up">
                    <h1>BATISSONS LE FUTUR!</h1>
                    <p>
                        Appelez-nous,laissez un Email ou un Message sur nos reseaux sociaux nous feront de notre mieux pour vous repondre dans le 24h.Nous serons heureux d'echanger sur vos preoccupations.

                    </p>
                    <p><span>Nom de l'entreprise:</span> Kivu Urbanist's Company.</p>
                    <p><span>Type de societe:</span> SARL</p>
                 </div>

                <div className="coords">
                    <div data-aos="zoom-in">
                        <h3>TELEPHONE</h3>
                        <h2><span>Cliquez pour Telephoner: </span><a href="tel:+243975184038">+243 975-184-038 <i className="bx bx-link-external"></i></a></h2>
                        <h2><span>Cliquez pour Telephoner: </span><a href="tel:+243975184038">+243 823-869-045 <i className="bx bx-link-external"></i></a></h2>
                        <p><span>HORAIRES</span></p>
                        <p>Lundi-Vendredi:8H00-17H00 Samedi-Dimanche:9H00-15H00</p>
                    </div>
                    <div data-aos="zoom-in">
                        <h3>EMAIL</h3>
                        <h2><span>Cliquez pour Envoyer un email: </span><a href="mailto:contact@kivuurbanistcompany.com">contact@kivuurbanistcompany.com <i className="bx bx-link-external"></i></a></h2>
                        <p><span>HORAIRES</span></p>
                        <p>24/7</p>
                    </div>
                    <div data-aos="zoom-in">
                        <h3>ADRESSE</h3>
                        <p><span>Adresse: </span>N-22,cellule LUSANDO,Q.VUTETSE,C/KIMENI, Ville de BUTEMBO, NORD-KIVU/RDCONGO.</p>
                        <p><span>Reference: </span>Immeuble jaune R+2, En face du MUSEE NATIONAL DE BUTEMBO.</p>
                    </div>
                    <div className="social">
                        <h2>
                        <a href="https://twitter.com/KucRdc" target='_blank' rel='noopener noreferrer'>Twitter <i className="bx bx-link-external"></i></a>
                        </h2>
                        <h2>
                        <a href="https://web.facebook.com/profile.php?id=100083668966211" target='_blank' rel="noopener noreferrer">Facebook <i className="bx bx-link-external"></i></a>
                        </h2>
                        <h2>
                        <a href="https://web.facebook.com/profile.php?id=100083668966211" target='_blank' rel="noopener noreferrer">Linkedin <i className="bx bx-link-external"></i></a>
                        </h2>
                    </div>
                    <a  href="https://www.google.com/maps/place/0%C2%B006'38.6%22N+29%C2%B017'38.4%22E/@0.1107088,29.2914149,17z/data=!3m1!4b1!4m4!3m3!8m2!3d0.1107088!4d29.2939898?hl=fr-CD&entry=ttu" className='localiser' target='_blank' rel='noopener noreferrer'>
                        <h2 className='located'><i className="bx bxs-location-plus"></i>NOUS LOCALISER</h2>
                    </a>
                </div>
           </main>
            <Footer />
        </div>
    )
}

export default Contact;
