import React from 'react'
import Slider from 'react-slick';
import partenaires from '../data/partenaires'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



function Partenaires() {
  let windowWith = document.documentElement.clientWidth;
  console.log(windowWith + "px")
  let slide = 0;
if(windowWith < 600){
  slide = 1; 
}else{
  slide = 2;
}
    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: slide,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
      };
      return (
        <>
          <div className="content partenaires">
            <h1>Ils nous font confiance</h1>
            <div className="container">
              <Slider {...settings}>
                {partenaires.map((item) => (
                  <div key={item.id} className='item' >
                    <img src={item.src} alt={item.alt} className="img" />
                    <h2 className="title">{item.title}</h2>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </>
      );
}

export default Partenaires
