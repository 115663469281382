import React, { useState, useEffect } from 'react';

function Stats() {
    const [projectsCompleted, setProjectsCompleted] = useState(0);
    const [clientsSatisfied, setClientsSatisfied] = useState(0);
    const [partners, setPartners] = useState(0);
    const [presences, setPresences] = useState(0);

    useEffect(() => {
        const animateStatistics = (target, setter) => {
            const incrementStep = Math.ceil(target / 50); // Ajustez le pas d'incrémentation selon vos besoins
            const animationInterval = 100; // Durée entre chaque incrémentation en millisecondes

            let currentValue = 0;

            const animation = () => {
                if (currentValue + incrementStep >= target) {
                    setter(target);
                } else {
                    currentValue += incrementStep;
                    setter(currentValue);
                    setTimeout(animation, animationInterval);
                }
            };

            animation();
        };

        const statsElements = document.querySelectorAll('.stat');
        const options = {
            threshold: 0.5, // Pourcentage de visibilité à partir duquel l'observation sera déclenchée
        };

        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    animateStatistics(75, setProjectsCompleted);
                    animateStatistics(175, setClientsSatisfied);
                    animateStatistics(30, setPartners);
                    animateStatistics(180, setPresences);
                    observer.disconnect(); // Une fois déclenché, nous n'avons plus besoin de l'observateur
                }
            });
        }, options);

        statsElements.forEach(element => {
            observer.observe(element);
        });

        return () => observer.disconnect(); // Nettoyage de l'observateur lors du démontage du composant
    }, []);

    return (
        <div className='statistique'>
            {/* stat self div */}
            <div className="stat">
                <i className="bx bx-building"></i>
                <h1>{projectsCompleted}</h1>
                <p>projets terminés</p>
            </div>
            {/* stat self div */}
            {/* stat self div */}
            <div className="stat">
                <i className="bx bx-trophy"></i>
                <h1>{clientsSatisfied}</h1>
                <p>clients satisfaits</p>
            </div>
            {/* stat self div */}
            {/* stat self div */}
            <div className="stat">
                <i className="bx bx-user"></i>
                <h1>{partners}</h1>
                <p>Partenaires</p>
            </div>
            {/* stat self div */}
            {/* stat self div */}
            <div className="stat">
                <i className="bx bx-cabinet"></i>
                <h1>{presences}</h1>
                <p>présences</p>
            </div>
            {/* stat self div */}
        </div>
    );
}

export default Stats;
