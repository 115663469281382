const equipe = [
    {
        id: 0,
        name: "Providence Siriwayo",
        img: "./img/urbanists/1.jpg",
        desc: "C.E.O | Urbaniste (Expert en planification et S.I.G)",
        facebook: "https://web.facebook.com/franklin.muhindo.3",
        linkedin: "https://www.linkedin.com/in/urbaniste-providence-siriwayo-franklin-a862a3228/",
        twitter: "https://twitter.com/ProvaSiriwayo",
        email: "mailto:providencesiriwayo12@gmail.com"
    },
    {
        id: 1,
        name: "Isaac Uzimati",
        img: "./img/urbanists/3.jpg",
        desc: "Directeur Technique (TD) | Urbaniste",
        facebook: "https://web.facebook.com/isaac.uzimati",
        linkedin: "https://www.linkedin.com/in/urbaniste-isaac-uzimati-djurua-9082391b5/",
        twitter: "https://twitter.com/IrUzimati",
        email: "mailto:isaacuzimati@gmail.com"
    },
    {
        id:5,
        name:"Jean-claude Lisaau",
        "img":"./img/urbanists/6.JPG",
        desc:` Conseiller Principal en Urbanisme`,
        facebook:"https://web.facebook.com/profile.php?id=100084940212012&sk=photos",
        linkedin:"https://fr.linkedin.com/in/jean-claude-lisaau-38b5b0b9",
        twitter:"https://twitter.com/Prof_Akuku/media",
        email:"mailto:prof.jcarchitecte@gmail.com"
    },
];

export default equipe;
