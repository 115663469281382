import React from 'react';
import projets from '../data/projets';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Projet() {
  return (
    <div className='projet'>
      <Header title="NOS PROJETS"   videoSrc='./video/2.mp4' />
      <main>
        <h1>
          Voici quelques-uns de nos <span>projets</span>
        </h1>
        <div className="projectPush">
          {projets.map((projet) => (
            <RecentProjects key={projet.id} img={projet.img} title={projet.title} desc={projet.desc} />
          ))}
        </div>
        {/* Vidéos */}
        <div className="videoContainer">
          <h1>Vidéo d'illustration</h1>
          <p>Restructuration d'une aire de centralité de KINTAMBO MAGASIN | KINSHASA</p>
          <video controls>
            <source src='./video/1.mp4' />
          </video>
        </div>
        {/* Vidéos */}
      </main>
      <Footer />
    </div>
  );
}

function RecentProjects(props) {
  return (
    <div className='project'>
      <div className="top">
        <img src={props.img} width={200} alt={props.title} />
      </div>
      <div className="bottom">
        <h1>{props.title}</h1>
        <p>{props.desc}</p>
      </div>
    </div>
  );
}

export default Projet;
