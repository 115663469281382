const partenaires = [
    {
      id: 1,
      src: "./img/partenaires/Cartouche.png",
      alt: "Image 1",
      title: "Cartouche Construct",
      description:"",
    },
   
    {
      id: 3,
      src: "./img/partenaires/ucbc.png",
      alt: "Image 3",
      title: "UCBC",
      description: 
        "",
    },
    {
      id: 4,
      src: "./img/partenaires/ups.png",
      alt: "Image 4",
      title: "UPS",
      description:
        "",
    },
    {
      id: 5,
      src: "./img/partenaires/SOPROCOPIV.png",
      alt: "Image 5",
      title: "SOPROCOPIV/CAFE",
      description:
        "",
    }, 
    {
        id: 2,
        src: "./img/partenaires/openstreetmap.png",
        alt: "Image 2 ",
        title: "OpenStreetMap RDC",
        description:'',
      },
    {
      id: 6,
      src: "./img/partenaires/corporation.jpg",
      alt: "Image 6",
      title: "corporation des urbanistes congolais",
      description:
        "",
    },
    {
      id: 7,
      src: "./img/partenaires/urban.png",
      alt: "Image 7",
      title: "Urban-analyse",
      description:
        "",
    },
  ];
  
  export default partenaires;