import React from 'react';
import services from '../data/services';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Service() {
   
    return (
        <div className='services'  >
            <Header title="Nos Services"  videoSrc='./video/2.mp4' />
            <main>
                <div className="topdiv">
                    <h1>Une équipe <span>jeune</span> et <span>multiservices</span></h1>
                </div>
                <div className="servicesPush">
                    {services.map((service) => 
                        <RecentServices key={service.id} icon={service.icon} title={service.title} />
                    )}
                </div>
            </main>
            <Footer />
        </div>
    );
}

function RecentServices(props) {
    return (
        <div className='service' data-aos="zoom-in">
            <div className="top">
                <img src={props.icon} alt={props.title} />
            </div>
            <div className="bottom">
                <p>{props.title}</p>
            </div>
        </div>
    );
}

export default Service;
