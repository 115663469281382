import React from "react";
import  { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import './styles/style.scss'
import { BrowserRouter , Routes, Route } from 'react-router-dom'
import Home from "./pages/Home";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import AllEquipe from "./pages/AllEquipe";
import Projet from "./pages/Projet";
import About from "./pages/About";
import Blog from "./pages/Blog";

const EventEmitter = require('events');
const myEmitter = new EventEmitter();
myEmitter.setMaxListeners(150);






function App() {

    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route  element={<Home />} path="*" />
          <Route index element={<Home />} path="/"/>
          <Route element={<Services />} path='/services' />
          <Route element={<Contact />} path='/contact' />
          <Route element={<AllEquipe />} path="/ourTeam" />
          <Route element={<Projet />} path="/ourprojects" />
          <Route element={<About/>} path="/aboutUs" />
          <Route element={<Blog/>} path="/blog" />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
