import React from 'react'
import equipe from '../data/equipe';
import { Link } from 'react-router-dom';

function Equipe() {
       // scroll on top script
       const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
    // scroll on top script
    return (
        <div className='equipe'>
            <div className="top" data-aos="fade-right">
                <p>Notre Equipe</p>
                
                <Link onClick={scrollToTop} to='/ourTeam'>Tout voir <i className="bx bx-right-arrow-alt bx-fade-right"></i></Link>
            </div>
            <div className="teamPush" >
                {equipe.map((equipe) => 
                    <Recentequipes key={equipe.id} img={equipe.img} name={equipe.name} desc={equipe.desc} linkedin={equipe.linkedin}
                     facebook={equipe.facebook} twitter={equipe.twitter} email={equipe.email} />
                )}
            </div>
        </div>
    )
}

function Recentequipes(props){
    return (
       <>
        <div key={props.id} className='equipeSelf' data-aos="fade-up">
            <div className="top" >
                <img src={props.img} alt="img" />
            </div>
            <div className="bottom">
                <h1>{props.name}</h1>
                <p>{props.desc}</p>
                <div className="social">
                    <a href={props.linkedin} target="_blank" rel="noopener noreferrer"><i className="bx bxl-linkedin"></i></a>
                    <a href={props.facebook} target="_blank" rel="noopener noreferrer"><i className="bx bxl-facebook"></i></a>
                    <a href={props.twitter} target="_blank" rel="noopener noreferrer"><i className="bx bxl-twitter"></i></a>
                    <a href={props.email} target="_blank" rel="noopener noreferrer"><i className="bx bxl-gmail"></i></a>
                </div>
            </div>
        </div>
       </>
    )
}
export default Equipe;
