let rapport = [
   {
    name : `1.RAPPORT D’ETUDE CARTOGRAPHIQUE ET
    ENVIRONNEMENTALE POUR LE PROGRAMME DE
    DEVELOPPEMENT LOCAL DEDIE AUX 145 TERRITOIRES
    (PDL-145T)
    «TERRITOIRE DE BASANKUSU»`,

    src :"./video/RAPPORT1.pdf",
    id : 3561,
   },
    {
        name : `2.RAPPORT DE MISSION D’ETUDE DE LEVE TOPOGRAPHIQUE DU JARDIN DES MORTS : NECROPOLE  «Ville de KINSHASA»`,
    src :"./video/RAPPORT2.pdf",
    id : 5782,
    },
    {
        name : `3.Rapport de la Mission de Collecte des Données
        Cartographiques des Zones Minières du site
        OKOVU, MBEMBELETE et ALIMA dans le
        Territoire de Mambasa en Ituri/RD.Congo`,
    src :"./video/RAPPORT4.pdf",
    id : 24683,
    },
]

export default rapport;