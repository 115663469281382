import React from 'react'
import Stats from '../components/Stats';
import Form from './Form';

function Footer() {
    
    return (
       <>
          <Stats/>
        <div className="footer">






            <div className="top">
                <h1>Parlons !</h1>
            </div>
            <div className="bottom">
                <div className="left">
                <p className='miniText'>Parlez-nous de votre projet via ces contacts ci-dessous.  <i class='bx bx-downvote bx-fade-down'></i></p>
                    <p><i class='bx bxl-gmail'>: </i><a href='mailto:contact@kivuurbanistcompany.com'>contact@kivuurbanistcompany.com</a></p>
                    <p><i class='bx bx-phone'>: </i><a href='tel:+243975184038'>+243 975-184-038</a></p>
                    <p><a href='tel:+243823869045'>+243 823-869-045</a></p>
                </div>
                <div className="right">
                  {/* push form component  */}
                    <Form/>
                    {/* push form component  */}
                    <div className="links">
                    <a href="https://twitter.com/KucRdc" target='_blank' rel='noopener noreferrer'>twitter</a>
                    <a href="https://web.facebook.com/profile.php?id=100083668966211" target='_blank' rel="noopener noreferrer">facebook</a>
                    <a href="https://wa.me/243975184038" target='_blank' rel="noopener noreferrer">whatsapp</a>
                    </div>
                   
                </div>
            </div>
        
            <div className="foot">
                <p>KUC 2024 &copy; all right reserved</p>
                <p>Designed with <i className="bx bxs-heart"></i> by <a href="https://kabeya243.github.io" target='_blank' rel='noopener noreferrer'>Kabeya ilunga</a></p>
            </div>
        </div>
       </>
    )
}

export default Footer;
