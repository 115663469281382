import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Form = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_oe2d2ct', 'template_ijrdh9h', form.current, {
        publicKey: 'DCGbW_-i_PkWXwTUy',
      })
      .then(
        () => {
          toast.success('SUCCESS! Votre email a été envoyé.');
        },
        (error) => {
          toast.error(`FAILED... Une erreur s'est produite: ${error.text}`);
        },
      );
  };

  return (
    <div className="form">
      <form ref={form} onSubmit={sendEmail} className="formulaire">
        <input type="text" name="user_name" placeholder="votre nom" />
        <input type="email" name="user_email" placeholder="votre adresse mail" />
        <textarea name="message" placeholder="votre message" />
        <input type="submit" value="Send" />
      </form>
      <ToastContainer />
    </div>
  );
};

export default Form;
