import React from 'react';
import { Link } from 'react-router-dom';
import Darkmode from './Darkmode';

function Navigation() {
    // a termier 

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
    // a termier 

    function changeclass(){
        let menu = document.querySelector(".menu");
        menu.classList.toggle("active");
    }


    return (
        <div className="navigation">
            <div className="logo">
                <img src="./img/thumb.png" width={80}  alt="logo" />
            </div>
            <i  onClick={changeclass} className="bx bx-menu openMenu"></i>
            <div className='menu'>
            <i onClick={changeclass} className="bx bx-x closeMenu" ></i>
                <Link onClick={scrollToTop} className='navlink' to='/'>Home</Link>
                <Link onClick={scrollToTop} className='navlink' to='/services'>services</Link>
                <Link onClick={scrollToTop} className='navlink' to='/ourprojects'>Projets</Link>
                <Link onClick={scrollToTop} className='navlink' to='/ourTeam'>Equipe</Link>
                <Link onClick={scrollToTop} className='navlink' to="/aboutUs">About</Link>
                <Link onClick={scrollToTop} className='navlink' to='/blog'>Blog</Link>
                <Link onClick={scrollToTop} className='navlink' to='/contact'>Contact</Link>
                <Darkmode />
            </div>
        </div>
    )
}

export default Navigation
