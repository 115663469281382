const articles = [
    {
        key: 1,
        title: "Butembo a besoin d'un plan qui doit régir l'occupation du sol selon les normes de construction",
        author: "Providence Siriwayo | Urbaniste",
        source: "Radio Elimu | UOR",
        img: "./img/blog/1.jpg",
        date: "7 juillet 2023",
        time: "5 min de lecture",
        link: "https://radioelimu.uor-rdc.net/butembo-a-besoin-dun-plan-qui-doit-regir-loccupation-du-sol-selon-les-normes-de-construction-urbaniste-providence-siriwayo/",
        miniText: "Les défis sont vraiment énormes. Nous sommes en train d’assister à certains risques, à certaines catastrophes, à des situations qui peuvent déshonorer notre profession liée à la planification et à la construction. Récemment, nous avons été témoins de l'effondrement d'une maison. Le défi consiste d'abord à intégrer la planification dans la ville, c'est-à-dire à mettre en place un plan qui doit régir...",
        content: "lorem."
    },
    {
        key : 2,
        title : "l'importance du role des ingenieurs architecte et urbaniste dans le developpement des provinces en rdc,selon l'urbaniste franklin siriwayo.",
        author: "Providence Siriwayo | Urbaniste",
        source : "Libre Grand Lac",
        date: "14 octobre 2023",
        img: "./img/blog/2.jpeg",
        time : "4 min de lecture",
        link : "https://libregrandlac.com/article/7120/l-importance-du-role-des-ingenieurs-architectes-et-urbanistes-dans-le-developpement-des-provinces-en-rdc--selon-l-urbaniste-franklin-siriwayo-",
        miniText : "Dans de nombreux pays en développement, de nombreuses personnes n'ont pas accès à un logement décent ni à des installations sanitaires. Le bien-être physique, psychologique, social et économique d'un individu dépend de ses conditions de vie dans un logement sûr et salubre, offrant les services essentiels...",
    },
];

export default articles;
