import React from 'react';
import Navigation from './Navigation';

function Header(props) {
    return (
        <div className='header'>
            <Navigation />
            <video autoPlay muted loop className='backgroundVideo'>
                <source src={props.videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="layer"></div>
            <div className="content">
                <h1 className='headerTitle' data-aos="zoom-in">{props.title}</h1>
                <p>{props.description}</p>
            </div>
        </div>
    );
}

export default Header;
