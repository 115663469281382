import React from 'react'
import works from '../data/recent';
import { Link } from 'react-router-dom';

function Recent() {
       // scroll on top script
       const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      };
    // scroll on top script
    return (
        <div className='recent'>
            <div className="top" data-aos='fade-right'>
                <p>Projets</p>
                <Link onClick={scrollToTop} to='/ourprojects'>Tout voir <i className="bx bx-right-arrow-alt bx-fade-left"></i></Link>
            </div>
            <div className="worksPush">
                {works.map((work) => 
                    <RecentWorks key={work.id} img={work.img} title={work.title} desc={work.desc} />
                )}
            </div>
        </div>
    )
}

function RecentWorks(props){
    return (
       <>
        <div key={props.id} className='work'  data-aos="zoom-in">
            <div className="top">
                <img src={props.img} width={200} alt="img" />
            </div>
            <div className="bottom">
                <h1>{props.title}</h1>
                <p>{props.desc}</p>
            </div>
        </div>
       </>
    )
}
export default Recent;
