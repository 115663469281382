const allEquipe = [
    {
        id:0,
        name:"Providence Siriwayo",
        img:"./img/urbanists/1.jpg",
        desc:`C.E.O | Urbaniste(Expert en planification et S.I.G)`,
        facebook:"https://web.facebook.com/franklin.muhindo.3",
        linkedin:"https://www.linkedin.com/in/urbaniste-providence-siriwayo-franklin-a862a3228/",
        twitter:"https://twitter.com/ProvaSiriwayo",
        email:"mailto:providencesiriwayo12@gmail.com"
    },
    {
        id:1,
        name:"Isaac Uzimati",
        "img":"./img/urbanists/3.jpg",
        desc:`Technical Director(TD) | urbaniste`,
        facebook:"https://web.facebook.com/isaac.uzimati",
        linkedin:"https://www.linkedin.com/in/urbaniste-isaac-uzimati-djurua-9082391b5/",
        twitter:"https://twitter.com/IrUzimati",
        email:"mailto:isaacuzimati@gmail.com"
    },
    {
        id:2,
        name:"Juscar Mbalavahi",
        "img":"./img/urbanists/2.jpg",
        desc:`Urbaniste`,
        facebook:"https://web.facebook.com/irjuscar.mbalavahi",
        linkedin:"https://www.linkedin.com/in/ir-juscar-mbalavahi-141b65284/",
        twitter:"https://www.linkedin.com/in/ir-juscar-mbalavahi-141b65284/",
        email:"mailto:juscarmbalavahiurban@gmail.com"
    },

    {
        id:3,
        name:"Richard ngowire",
        "img":"./img/urbanists/4.jpg",
        desc:`Project Manager`,
        facebook:"https://web.facebook.com/kakulengowire.richard",
        linkedin:"https://www.linkedin.com/in/ngowire-richard-5959aa285/",
        twitter:"https://twitter.com/NgowireUrban",
        email:"mailto:urbanrichardngowire@gmail.com"
    },
    {
        id:4,
        name:"Kasereka Stiven",
        "img":"./img/urbanists/5.jpg",
        desc:`Urbaniste`,
        facebook:"https://web.facebook.com/profile.php?id=100008420047938",
        linkedin:"https://www.linkedin.com/in/stiven-mulemberi-382973284/",
        twitter:"https://twitter.com/KaserekaStiven",
        email:"mailto:mulemberistiven123@gmail.com"
    },
    
    {
        id:6,
        name:"Dieumerci Saghasa",
        desc:"Urbaniste",
        img:"./img/urbanists/7.JPG",
        facebook:"https://web.facebook.com/dieumerci.saghasa",
        linkedin:"https://cd.linkedin.com/in/dieumerci-saghasa-8a72051b3",
        twitter:"https://twitter.com/DieumeSaghasa",
        email:""
    },
    {
        id:7,
        name:"Muhindo Reagan",
        desc:"Urbaniste",
        "img":"./img/urbanists/8.JPG",
        facebook:"",  
        linkedin:"https://www.linkedin.com/ibrahim-vihuko-439643285",
        email:"ibrahimvihuko2@gmail.com"
    },
    {
        id:8,
        name:"Orlin Bujiriri",
        desc:"Expert en Topographie & SIG",
        "img":"./img/urbanists/9.JPG",
        facebook:"https://web.facebook.com/orlin.bujiriri.9/",  
        linkedin:"",
        email:"orlinbujiriri2@gmail.com"
    },
    {
        id:5,
        name:"Jean-claude Lisaau",
        "img":"./img/urbanists/6.JPG",
        desc:` Conseiller Principal en Urbanisme`,
        facebook:"https://web.facebook.com/profile.php?id=100084940212012&sk=photos",
        linkedin:"https://fr.linkedin.com/in/jean-claude-lisaau-38b5b0b9",
        twitter:"https://twitter.com/Prof_Akuku/media",
        email:"mailto:prof.jcarchitecte@gmail.com"
    },
]
export default allEquipe;