const works = [
    {
        id: 0,
        title: "Pont Kyshali-Isango",
        img: "./img/projets/1.JPG",
        desc: "Construction d'un pont reliant la cellule Kyshali et Isango dans le quartier Mukuna | Butembo",
    },
    {
        id: 1,
        title: "Restructuration Kintambo Magasin | KINSHASA",
        img: "./img/projets/0.JPG",
        desc: "Restructuration d'une aire de centralité de KINTAMBO MAGASIN | KINSHASA",
    },
    {
        id: 8,
        title: "Étude et Levé Topographique du Jardin des Morts entre Terre et Ciel (Nécropole Kinshasa)",
        img: "./img/projets/10.jpg",
        desc: "Mise en place d'un plan de lotissement (trame assainie et extension du jardin de mort) et faciliter les études de conceptions architecturale",
    },
];

export default works;
