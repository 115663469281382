import React from 'react';
import allEquipe from '../data/allEquipe';
import Header from '../components/Header';
import Footer from '../components/Footer';

function Equipe() {
   
    return (
        <div className='allequipe'>
            <Header title="NOTRE ÉQUIPE"   videoSrc='./video/2.mp4'/>
            <main>
                <div className="top">
                    <h1>La team au complet</h1>
                    <p>
                        Cette équipe de jeunes experts a également pour mandat d'accompagner les structures locales dans la maîtrise
                        et la gestion résiliente des ressources locales face aux menaces et aux risques urbains multifactoriels, en les aidant
                        à créer et à mettre à jour des bases de données cartographiques pour la gestion intelligente des projets et de l'espace.
                    </p>
                </div>
                <div className="teamPush">
                    {allEquipe.map((equipe) => 
                        <Recentequipes key={equipe.id} img={equipe.img} name={equipe.name} desc={equipe.desc} linkedin={equipe.linkedin}
                            facebook={equipe.facebook} twitter={equipe.twitter} email={equipe.email} />
                    )}
                </div>
                <div className="integration">
                    <h1>Voulez-vous intégrer la grande famille des Urbanistes du Kivu ?</h1>
                    <p>Veuillez compléter ce bulletin d'adhésion et nous l'envoyer par e-mail ou via WhatsApp.</p>
                    <div className="pdf">
                        <a href="./video/Bulletin.pdf" download>Télécharger le bulletin d'adhésion <i className="bx bxs-download"></i></a>
                    </div>
                    <div className="soumis">
                        <h3>Soumettez le bulletin ici :</h3>
                        <div>
                            <a href="https://wa.me/+243975184038" target="_blank" rel="noopener noreferrer"><i className="bx bxl-whatsapp"></i></a>
                            <a href="mailto:contact@kivuurbanistscompany.com" target='_blank' rel='noopener noreferrer'><i className="bx bx-envelope"></i></a>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>
    );
}

function Recentequipes(props) {
    return (
        <div key={props.id} className='equipeSelf' data-aos="fade-up">
            <div className="left">
                <img src={props.img} alt={props.name} />
            </div>
            <div className="right">
                <h1>{props.name}</h1>
                <p>{props.desc}</p>
                <div className="social">
                    <a href={props.linkedin} target="_blank" rel="noopener noreferrer"><i className="bx bxl-linkedin"></i></a>
                    <a href={props.facebook} target="_blank" rel="noopener noreferrer"><i className="bx bxl-facebook"></i></a>
                    <a href={props.twitter} target="_blank" rel="noopener noreferrer"><i className="bx bxl-twitter"></i></a>
                    <a href={props.email} target="_blank" rel="noopener noreferrer"><i className="bx bxl-gmail"></i></a>
                </div>
            </div>
        </div>
    );
}

export default Equipe;
