import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

function About() {
    return (
        <div className='about'>
            <Header title="À PROPOS DE NOUS"   videoSrc='./video/2.mp4' />
            <main>
                <div className="historique">
                    <div className="left">
                        <div className="top">
                            <img src="./img/logo.png" alt="logo KUC Company" />
                        </div>
                        <div className="bottom" data-aos="fade-up">
                            <h1>HISTORIQUE</h1>
                            <p>
                                Kivu Urbanist’s Company est un cabinet d’études crée en mars 2022 par de jeunes valeureux experts et 
                                membres de la Corporation nationale des Urbanistes Congolais basés à l’Est de la République Démocratique du Congo. Il est spécialisé dans le domaine du génie territorial et urbain : l’aménagement et la planification spatiale durable, la mobilité intelligente et partagée, l’assainissement, la gestion résiliente des risques urbains, la construction, la protection ainsi que la conservation de l’environnement par le « Smart management et l’intelligence artificielle (IA) » orientée vers le Système d’Information Géographique (SIG) et la géomatique.
                            </p>
                            <h1>LOCALISATION</h1>
                            <p>
                                Il est basé en République Démocratique du Congo, dans les villes de KINSHASA et Butembo au Nord-Kivu.
                            </p>
                            <p><span>Adresse : </span>N-22, cellule LUSANDO, Q. VUTETSE, C/KIMENI, Ville de BUTEMBO, NORD-KIVU, RDCONGO.</p>
                            <p><span>Référence : </span>Immeuble jaune R+2, En face du MUSÉE NATIONAL DE BUTEMBO.</p>
                            <h1>FORME JURIDIQUE</h1>
                            <p>
                                K.U.C/Sarl est une société à responsabilité limitée de droit congolais disposant des documents administratifs légaux Reconnue par l'état Congolais.
                            </p>
                            <p>Numéro de Registre de commerce :  <b>CD/KNG/RCCM/22-B-03105</b>.</p>
                            <p>Identification nationale : <b>01-H5300-N22483A</b>.</p>
                        </div>
                    </div>
                    <div className="right" data-aos="fade-up">
                        <h1>Objectifs et Activités de KUC.</h1>
                        <p>
                            La société a pour objet principal, en République Démocratique du Congo, qu’en dehors du pays, soit tant pour elle-même que pour le compte des tiers, personnes physiques ou morales, soit conjointement ou en participation ou sous toute autre forme légale, toutes activités se rapportant directement ou indirectement aux :
                        </p>
                        <ul>
                            <li>Planification des projets de développement local, urbain et régional ;</li>
                            <li>Aménagement des aires protégées, zones d’activités et économiques spéciales ;</li>
                            <li>Gestion, protection et étude d’impacts environnementaux ;</li>
                            <li>L’inventaire multi-ressource et aménagement des forêts ;</li>
                            <li>La foresterie, l’agroforesterie et le reboisement ;</li>
                            <li>L’agriculture et l’élevage ;</li>
                            <li>L’hydrogéologie ;</li>
                            <li>Mines et géologie ;</li>
                            <li>L’horticulture et les cultures ornementales ;</li>
                            <li>La gestion de la main-d’œuvre qualifiée et ouvrière ;</li>
                            <li>La formation et le recyclage de la main-d’œuvre ;</li>
                            <li>Conception et gestion des projets ;</li>
                            <li>SIG et géomatique (télédétection et topographie) ;</li>
                            <li>Création de base de données géographiques ;</li>
                            <li>Bases pour la réalisation des travaux routiers, eaux potables, ajustage, structure métallique et électricité, etc.</li>
                        </ul>
                        <h1>NOS PARTENAIRES</h1>
                        <ul>
                            <li>La société de production et de commercialisation du café SOPROCOPIV/RDC ;</li>
                            <li>La société OpenStreetMap Butembo ;</li>
                            <li>Unité de planification spatiale (UPS/ISAU – Kinshasa) ;</li>
                            <li>Cartouche construction de Kinshasa ;</li>
                            <li>La Corporation Nationale des Urbanistes Congolais ;</li>
                            <li>Bureau d’Études de Cartographie et d’Aménagement du Territoire, en sigle BECAT Sarl ;</li>
                            <li>Institut des Recherches Intégrées (IRI/UCBC) : partenaire souhaité ;</li>
                            <li>Département de l’Urbanisme de l’Institut du Bâtiment et des Travaux Publics (IBTP/Butembo) : partenaire souhaité, etc.</li>
                        </ul>
                    </div>
                </div>
                <div className="capacity" data-aos="fade-up">
                    <h1>CAPACITÉ HUMAINE</h1>
                    <h2>Qualifications & Nombre</h2>
                    <ul>
                        <li>Experts en coordination et évaluation des projets : 2</li>
                        <li>Experts en Urbanisme, Aménagement et Planification spatiale : 15</li>
                        <li>Experts en environnement et gestion des aires protégées : 8</li>
                        <li>Experts Géomètres-topographes : 4</li>
                        <li>Experts en Économie et sciences sociales : 3</li>
                        <li>Experts en Agronomie : 3</li>
                        <li>Expert comptables : 1</li>
                        <li>Expert Gestion des projets : 2</li>
                    </ul>
                    <h2>TOTAL : 38</h2>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default About;
